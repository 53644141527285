<template>
  <FontAwesomeIcon
    :icon="icon"
    :title="t(`resources.presences.fields.periods.${props.period}`)"
    class="h-6 w-6 text-comeen-gray"
  />
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faSunBright,
  faSunset,
  faSunrise,
} from '@fortawesome/pro-thin-svg-icons'

const { t } = useI18n({ useScope: 'global' })

const props = defineProps({
  period: {
    type: String,
    required: true,
    validator: (value) => ['all_day', 'morning', 'afternoon'].includes(value),
  },
  default: 'all_day',
})

const icon = computed(() => {
  if (props.period == 'morning') {
    return faSunrise
  } else if (props.period == 'afternoon') {
    return faSunset
  } else {
    return faSunBright
  }
})

defineOptions({
  name: 'ActivityPeriodIcon',
})
</script>
